import { API_ORIGIN, SMZDM } from "./constants";
import LocalStorage from '@/lib/localStorage';
import SessionStorage from '@lib/session';
import { BXL_USER_ID, BXL_USER_KEY, BXL_GUEST_ID, BXL_UTM_PARAMS } from '@lib/constants';
import { platform } from '@lib/platform';
import history from '@lib/history';
import axios from 'axios';
import { isBrowser } from '@lib/utils'
// const { default: axios } = require('axios');

const http = axios.create({
  baseURL: API_ORIGIN,
});
let uuid: string = '';
let uid: string = '';
if (LocalStorage.get(BXL_USER_ID)) {
  uuid = LocalStorage.get(BXL_USER_ID) || '';
}
if (uuid) {
  let uids = uuid.split('-')
  uid = uids[uids.length - 1]
}

http.interceptors.request.use(
  function (config: any) {
    const utmParams = JSON.parse(SessionStorage.get(BXL_UTM_PARAMS) || '{}');

    // 在发送请求之前做些什么
    config.headers["X-Session-key"] = LocalStorage.get(BXL_USER_KEY) || "";
    config.headers["X-Session-user"] = LocalStorage.get(BXL_USER_ID) || LocalStorage.get(BXL_GUEST_ID) || "";
    if (isBrowser) {
      config.headers["X-BXL-User-Agent"] = `Bieyang H5WB/${platform} 0.1.0 (; UID '')''`;
    } else {
      config.headers["X-BXL-User-Agent"] = `Bieyang H5WB/ 0.1.0 (; UID '')''`;
    }
    return config;
  },
  function (error: any) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

// // 添加响应拦截器
http.interceptors.response.use(
  function (response: any): any {
    // 对响应数据做点什么
    return response.data;
  },
  function (error: any): any {
    // 对响应错误做点什么
    const code = error && error.response && error.response.status;
    if (code === 401) {
      //未登陆
      history.push('/login');
    }
    return Promise.reject((error.response && error.response.data) || error);
  }
);

// function beforeGet(url: string, data: object, config: any) {
//     return 
// }

// http.get.before = function(beforeGet) {
//   var _self = this;
//   return function() {
//     _self.apply(this, beforeGet.apply(this, arguments))
//   }
// }

// var _get = http.get;
// http.get = function(url: string, data: object, config: any) {
//   const _config = {...config, params: data};
//   return _get(url, _config);
// }

export default http;
