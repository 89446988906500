import React, { FC, useEffect, useState, useMemo } from 'react';
import ProductPC from './productPc';
import ProductMobile from './productMobile';
import './index.scss';
import * as styles from './product.module.scss';
import { initAnalytics, sendPageOpenEvent } from '@/service/trackingService';
import { getIdFromUrl, init } from '@/lib/utils';
import { launchProductDetailInApp } from '@/lib/appstore';
import { ProductProvider } from '@/components/Context';
import { getProductDetail, getProductRelated, getSimilarProduct, getTranslate, getProductComment } from '@/service/api';
import SEO from '../seo';
import { useMediaQuery } from "react-responsive";
import MobileLoading from '@/components/Loading';
import DesktopLoading from '@/layouts/Loading';

const Product: FC<any> = (props: any) => {
  const { pageContext } = props || {}
  const [loading, setLoading] = useState(false)
  const isDesktop = useMediaQuery({ query: "(min-width: 960px)" })
  const [productInfo, setProductInfo] = useState(() => {
    return pageContext?.product || {}
  })
  const [merchant, setMerchant] = useState(() => {
    return pageContext?.related?.merchant || {}
  })
  const [brand, setBrand] = useState(() => {
    return pageContext?.related?.brand || {}
  })
  const [similar, setSimilar] = useState(() => {
    return pageContext?.similar || []
  })
  const [comment, setComment] = useState<any>()
  const [translation, setTranslation] = useState('')
  const id = pageContext?.product?.id || getIdFromUrl() || ''
  useEffect(() => {
    init()
    initAnalytics()
    sendPageOpenEvent(id)
    launchProductDetailInApp(id)
  }, [])

  useEffect(() => {
    if (!id) return
    if (!productInfo?.id) {
      setLoading(true)
    }
    getProductDetail(id)
      .then((res: any) => {
        setProductInfo(res)
        setLoading(false)
      }).catch((err: any) => {
        setLoading(false)
        if (err?.code == 404) {
          // reset the data
          setProductInfo({})
          setComment(null)
          setMerchant({})
          setBrand({})
        }
        console.warn(err)
      })

    getProductComment(id, 10, 0, 'likes')
      .then((res: any) => {
        setComment(res)
      })
      .catch(() => {
        // console.warn(err)
      })

    getTranslate(id)
      .then((res: any) => {
        if (!!res?.description) {
          setTranslation(res?.description)
        }
      })
      .catch(() => {
        // console.warn(err)
      })

    getSimilarProduct(id)
      .then((res: any) => {
        setSimilar(res.products || [])
      })
      .catch(() => {
      })
  }, [id])

  useEffect(() => {
    if (!id || (!!brand && !!merchant)) return
    getProductRelated(id)
      .then((res: any) => {
        setMerchant(res?.merchant)
        setBrand(res?.brand)
      }).catch((err: any) => {
        console.warn(err)
      })
  }, [id, brand, merchant])

  const SeoComponent = useMemo(() => {
    if (!productInfo) return null
    const { id, displayBrand, brand, nameCN, name, category, attributes, images, priceTagCN, priceTag, favoritedCount, purchaseCount } = productInfo || {}
    const section1 = displayBrand || brand || ''
    const section2 = nameCN || name || ''
    const section3 = (purchaseCount > 5) ? `近期超过${purchaseCount}人购买 ` : (favoritedCount > 5 ? `近期${favoritedCount}人收藏 ` : '')
    const materials = attributes?.attributes?.material?.choices?.slice(0, 3) || []
    const categoryKeywords = category?.split('|')?.map((s: string) => s.trim())
    const priceSection = priceTagCN || priceTag || ''
    const showDiaplayBrand = (section1 && section2) ? section2?.indexOf(section1) === -1 : false
    return (
      <SEO
        title={`${showDiaplayBrand ? `[${section1}]` : ''}${section2 || '商品详情'} ${priceSection ? ` 价格${priceSection}` : ''} | 别样海外购`}
        description={`${showDiaplayBrand ? `${section1} ` : ''}${section2} ${materials?.join(' ')}${priceSection ? ` 价格${priceSection} ` : ' '}${section3}。 海淘专家一站式服务(bieyangapp.com) - 别样海外购`}
        keywords={`${section1},${section2},${categoryKeywords?.join(',')},${materials?.join(',')},别样海外购,海淘`}
        canonical={id ? `https://www.bieyangapp.com/pd/${id}/` : undefined}
        image={images?.length ? (images?.[0]?.full?.url || images?.[0]?.thumbnail?.url): undefined}
      />
    )
  }, [productInfo])

  return (
    <>
      {SeoComponent}
      <ProductProvider 
        value={{
          product: productInfo,
          merchant: merchant,
          brand: brand,
          similar: similar,
          updateMerchant: setMerchant,
          updateBrand: setBrand,
          updateSimilar: setSimilar,
          comment: comment,
          translation: translation,
          loading,
        }}
      >
        <ProductMobile id={id} {...props} isMobile={!isDesktop} className={styles.productMobile} />
        <ProductPC id={id} {...props} isPC={isDesktop} className={styles.productPc} />
        {loading ? (isDesktop ? <DesktopLoading /> : <MobileLoading />) : null}
      </ProductProvider>
    </>
  )
}

export default Product;